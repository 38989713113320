import React, { Fragment } from "react";
import NavBar from "../components/NavBar.jsx";
import TitleBox, { TitleBoxHeights } from "../components/TitleBox.jsx";
import Footer from "../components/Footer.jsx";
import { Helmet } from "react-helmet";

/** @jsx jsx */
import { jsx, Text } from "theme-ui";

const Template404 = ({ data }) => {
  return (
    <Fragment>
      <Helmet>
        <title>Not Found - Poliambulatorio Giano</title>
      </Helmet>
      <NavBar />
      <TitleBox heights={TitleBoxHeights.contentPageHeights}>
        <Text variant="pagesTitle">ERROR 404: OPS..</Text>
      </TitleBox>
      <div
        sx={{
          paddingTop: "10vh",
          paddingBottom: "10vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text variant="paragraphTitle" sx={{ color: "primary" }}>
          Sembra che la pagina che cerchi non esista...
        </Text>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Template404;
